import { Icon } from '@iconify-icon/react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomInput from '../CustomInput';
import successLogo from '../assets/success.svg';

const validationSchema = Yup.object({
  Firstname: Yup.string().required('First Name is required'),
  Lastname: Yup.string().required('Last Name is required'),
  Email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  Phone: Yup.string()
    .matches(/^\d{10}$/, 'Invalid phone number')
    .required('Phone number is required'),
  Department: Yup.string().required('Department is required'),
});

const VolunteerModal = ({ onClose }) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  const initialValues = {
    Firstname: '',
    Lastname: '',
    Email: '',
    Phone: '',
    Department: '',
  };

  const handleSubmit = async (values) => {
    const data = new FormData();
    data.append('Firstname', values.Firstname);
    data.append('Lastname', values.Lastname);
    data.append('Email', values.Email);
    data.append('Phone', values.Phone);
    data.append('Department', values.Department);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbwks3_FvqJ4dI3N_JqYpdcVAChxyLwKu-o1yu1w1d-HcI_CxqPB9X6gIUuAikE5MiSLmQ/exec',
        {
          method: 'POST',
          muteHttpExceptions: true,
          body: data,
        }
      );

      if (response.ok) {
        setIsSuccessful(true);
      } else {
        setIsError(true);
        console.error('Failed to submit form data:', response.statusText);
      }
    } catch (error) {
      setIsError(true);
      console.error('An error occurred while submitting form data:', error);
    }
  };

  const [toggle, setToggle] = useState();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`relative bg-white lg:h-fit h-full overflow-scroll lg:rounded-3xl shadow-lg py-10 lg:px-14 px-5  ${
          isSuccessful ? 'lg:w-[30%]' : 'lg:w-[40%]'
        } w-[100%] `}
      >
        {isSuccessful ? (
          <div className="lg:block  lg:h-fit h-[80vh] flex justify-center items-center">
            <div>
              {' '}
              <img
                src={successLogo}
                alt="successLogo"
                className="h-[100px] mx-auto"
              />
              <p className="text-center font-bold text-[20px] text-[#2D2D2D] mt-4">
                Thank you for volunteering, the Almighty God will reward you
                bountifully!
              </p>
              <button
                onClick={onClose}
                className="bg-[#A825B3] shadow mx-auto flex text-[#fff] px-10 py-[10px] mt-8 text-[14px] font-medium rounded-3xl"
              >
                Complete
              </button>
            </div>
          </div>
        ) : (
          <div>
            <button
              className="absolute flex items-center  font-[500] gap-1 lg:right-8 right-4 border border-[#5050504D] lg:text-sm text-[13px] text-[#A825B3] px-2 py-1 rounded-2xl"
              onClick={onClose}
            >
              Close
              <Icon
                icon="ic:outline-close"
                className="text-[#2D2D2D] text-lg"
              />
            </button>
            <h2 className="text-[16px] font-[500] text-center mb-8 text-[#505050]">
              Volunteer&apos;s Form
            </h2>
            <p className="mb-4 font-bold lg:text-[23px] text-[20px]">
              Become a Part of the extraordinary move of God&apos;s grace!
            </p>
            <p className="font-[500] mb-4">Personal information</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-3">
                      <CustomInput
                        id="Firstname"
                        name="Firstname"
                        type="text"
                        label="FIRST NAME"
                        placeholder="Enter first name"
                        isRequired={true}
                      />
                      <CustomInput
                        id="Lastname"
                        name="Lastname"
                        type="text"
                        label="LAST NAME"
                        placeholder="Enter last name"
                        isRequired={true}
                      />
                    </div>
                    <CustomInput
                      id="Phone"
                      name="Phone"
                      type="number"
                      label="PHONE NUMBER"
                      isRequired={true}
                    />
                    <CustomInput
                      id="Email"
                      name="Email"
                      type="email"
                      label="EMAIL"
                      isRequired={true}
                    />
                  </div>
                  <p className="font-[500] mt-6 mb-4">Department</p>

                  <div className="relative">
                    <label className=" text-[#505050] text-[10px] tracking-widest block mb-1">
                      <span>
                        DEPARTMENT YOU WANT TO SERVE?
                        <span className="text-[#F54135]">*</span>
                      </span>
                    </label>

                    <button
                      type="button"
                      aria-expanded={toggle}
                      onClick={setToggle}
                      className={`flex items-center justify-between p-3 w-full text-sm rounded-md  border-[1px]`}
                    >
                      <p>{values?.Department || 'Choose department'}</p>

                      <div
                        className={`transform transition-all ${
                          toggle ? 'rotate-0' : 'rotate-180'
                        }`}
                      >
                        <Icon
                          icon={'ic:outline-keyboard-arrow-down'}
                          color="#BFBFBF"
                          height={20}
                        />
                      </div>
                    </button>

                    {toggle && (
                      <div
                        className={`absolute z-20 mt-1 mb-20 flex h-[132px] w-full flex-col overflow-auto rounded-md border-2   `}
                      >
                        {[
                          'Sanitation',
                          'Medical',
                          'Security',
                          'Prayer',
                          'Follow-up',
                          'Evangelism',
                          'Media',
                          'Welfare',
                          'Protocol',
                          'Ushering',
                        ].map((item) => (
                          <p
                            onClick={() => {
                              setToggle(false);
                              setFieldValue('Department', item);
                            }}
                            className={`p-2 text-left text-sm bg-[#fff] hover:bg-[#BFBFBF] hover:text-white cursor-pointer`}
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>

                  {isError && (
                    <p className="text-red text-xs">
                      Oops, Something went wrong!
                    </p>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[#A825B3] shadow  text-[#fff] lg:w-fit w-full px-10 py-[10px] mt-8 lg:mb-0 mb-10 text-[14px] font-medium rounded-3xl disabled:opacity-60"
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default VolunteerModal;
