import { useState } from 'react';
import backgroundImage from '../assets/bg-desktop.jpg';
import backgroundImage2 from '../assets/bg-mobile.jpg';
import logo from '../assets/logo-desktop.svg';
import VolunteerModal from '../Forms/VolunteerModal';
import DonateModal from '../Forms/DonateModal';

function Event() {
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

  return (
    <div className="relative flex items-center justify-center">
      <img
        src={logo}
        alt="logo"
        className="fixed z-10 top-10 h-[250px]  hidden lg:block md:block"
      />
      <img
        src={backgroundImage}
        alt="Background"
        className="fixed hidden lg:block md:block inset-0 w-full h-screen object-cover object-bottom"
      />
      <img
        src={logo}
        alt="logo"
        className="fixed z-10 top-[6%] h-[150px] lg:hidden md:hidden"
      />
      <img
        src={backgroundImage2}
        alt="Background"
        className="fixed lg:hidden inset-0 w-full  h-full object-cover object-bottom md:hidden"
      />
      <div className="z-10 gap-6 lg:top-[40%]  md:top-[40%] top-[33%] flex mb-24 fixed">
        <button
          className="bg-[#FEC340] shadow w-[140px] text-[#2D2D2D] px-6 py-2 text-[18px] font-medium rounded-3xl"
          onClick={() => setIsVolunteerModalOpen(!isVolunteerModalOpen)}
        >
          Volunteer
        </button>
        <button
          onClick={() => setIsDonateModalOpen(!isDonateModalOpen)}
          className="bg-[#A825B3] shadow w-[140px] text-[#fff] px-6 py-2 text-[18px] font-medium rounded-3xl"
        >
          Donate
        </button>
      </div>
      {isVolunteerModalOpen && (
        <VolunteerModal
          onClose={() => setIsVolunteerModalOpen(!isVolunteerModalOpen)}
        />
      )}
      {isDonateModalOpen && (
        <DonateModal onClose={() => setIsDonateModalOpen(!isDonateModalOpen)} />
      )}
    </div>
  );
}

export default Event;
