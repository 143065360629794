import { Icon } from '@iconify-icon/react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomInput from '../CustomInput';

const validationSchema = Yup.object({
  Firstname: Yup.string().required('First Name is required'),
  Lastname: Yup.string().required('Last Name is required'),
  Phone: Yup.string().required('Phone Number is required'),
  Email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

const DonateMoney = ({ onClose, goBack, nextPage }) => {
  const [isError, setIsError] = useState(false);

  const initialValues = {
    Firstname: '',
    Lastname: '',
    Phone: '',
    Email: '',
    Location: '',
    AgreeCondition: false,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = new FormData();
    data.append('Firstname', values.Firstname);
    data.append('Lastname', values.Lastname);
    data.append('Email', values.Email);
    data.append('Phone', values.Phone);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbzpIawmBP_TwRR-9P4oME1JUjzim6SbjNhGWb3XHcOyORC3dE7qvZvppKMSlgFsyTRf/exec',
        {
          method: 'POST',
          muteHttpExceptions: true,
          body: data,
        }
      );

      if (response.ok) {
        nextPage();
      } else {
        setIsError(true);
        console.error('Failed to submit form data:', response.statusText);
      }
    } catch (error) {
      setIsError(true);
      console.error('An error occurred while submitting form data:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`relative bg-white lg:h-fit h-full  overflow-scroll  lg:rounded-3xl shadow-lg py-10 lg:px-14 px-5 lg:w-[40%] w-[100%]  `}
      >
        <div>
          <button
            className="absolute flex items-center font-[500] gap-1 lg:left-8 left-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
            onClick={goBack}
          >
            <Icon
              icon="eva:arrow-ios-back-outline"
              className="text-[#2D2D2D] text-lg"
            />{' '}
            Back
          </button>
          <button
            className="absolute flex items-center font-[500] gap-1 lg:right-8 right-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
            onClick={onClose}
          >
            Close
            <Icon icon="ic:outline-close" className="text-[#2D2D2D] text-lg" />
          </button>
          <h2 className="text-[16px] font-[500] text-center mb-8 text-[#505050]">
            Donate Money{' '}
          </h2>

          <p className="font-[500] mb-4">Personal information</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="space-y-3">
                  <div className="grid grid-cols-2 gap-3">
                    <CustomInput
                      id="Firstname"
                      name="Firstname"
                      type="text"
                      label="FIRST NAME"
                      placeholder="Enter first name"
                      isRequired={true}
                    />
                    <CustomInput
                      id="Lastname"
                      name="Lastname"
                      type="text"
                      label="LAST NAME"
                      placeholder="Enter last name"
                      isRequired={true}
                    />
                  </div>
                  <CustomInput
                    id="Phone"
                    name="Phone"
                    type="number"
                    label="PHONE NUMBER"
                    isRequired={true}
                  />
                  <CustomInput
                    id="Email"
                    name="Email"
                    type="email"
                    label="EMAIL"
                    isRequired={true}
                  />
                </div>
                {isError && (
                  <p className="text-red text-xs">
                    Oops, Something went wrong!
                  </p>
                )}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#A825B3] disabled:opacity-60 lg:w-fit w-full shadow  text-[#fff] px-10 py-[10px]  mt-8 lg:mb-0 mb-10 text-[14px] font-medium rounded-3xl"
                >
                  {isSubmitting ? 'Submitting...' : 'Proceed'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default DonateMoney;
