import { Icon } from '@iconify-icon/react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import CustomInput from '../CustomInput';
import successLogo from '../assets/success.svg';

const validationSchema = Yup.object({
  Firstname: Yup.string().required('First Name is required'),
  Lastname: Yup.string().required('Last Name is required'),
  Phone: Yup.string().required('Phone Number is required'),
  Email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  Location: Yup.string().required('Drop-off Location is required'),
});

const DonateCloth = ({ onClose, goBack }) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const initialValues = {
    Firstname: '',
    Lastname: '',
    Phone: '',
    Email: '',
    Location: '',
    AgreeCondition: false,
    SelectedItems: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = new FormData();
    data.append('Firstname', values.Firstname);
    data.append('Lastname', values.Lastname);
    data.append('Email', values.Email);
    data.append('Location', values.Location);
    data.append('Phone', values.Phone);
    data.append('AgreeCondition', values.AgreeCondition);

    let selectedItems = '';

    if (values.books) {
      selectedItems += 'books, ';
    }
    if (values.shoes) {
      selectedItems += 'shoes, ';
    }
    if (values.clothes) {
      selectedItems += 'clothes, ';
    }
    if (values.otherItem && values.otherItem.trim() !== '') {
      selectedItems += values.otherItem.trim() + ', ';
    }

    // Remove the trailing comma and space if no items were selected
    selectedItems = selectedItems.slice(0, -2);
    data.append('SelectedItems', selectedItems);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbygqXk4UxO6hUpqyDs493Clamqhsnox2H8kNrQ_HRQq2JBcWA--JNXsS5veG6Y6UdmO/exec',
        {
          method: 'POST',
          muteHttpExceptions: true,
          body: data,
        }
      );

      if (response.ok) {
        setIsSuccessful(true);
      } else {
        setIsError(true);
        console.error('Failed to submit form data:', response.statusText);
      }
    } catch (error) {
      setIsError(true);
      console.error('An error occurred while submitting form data:', error);
    }
  };

  const [toggle, setToggle] = useState();

  const handleCheckboxChange = (fieldName, setFieldValue, e, values) => {
    const { checked } = e.target;
    setFieldValue(fieldName, checked);

    // Update showOtherInput based on the selection of "others" checkbox
    if (fieldName === 'others') {
      setShowOtherInput(checked);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`relative bg-white lg:h-fit h-full  overflow-scroll  lg:rounded-3xl shadow-lg py-10 lg:px-14 px-5  ${
          isSuccessful ? 'lg:w-[30%]' : 'lg:w-[40%]'
        } w-[100%] `}
      >
        {isSuccessful ? (
          <div className="lg:block  lg:h-fit h-[80vh] flex justify-center items-center">
            <div>
              <img
                src={successLogo}
                alt="successLogo"
                className="h-[100px] mx-auto "
              />
              <p className="text-center font-bold text-[20px] text-[#2D2D2D] mt-4">
                Thank you for your generous donation, the Almighty God will
                reward you bountifully!
              </p>
              <button
                onClick={onClose}
                className="bg-[#A825B3] shadow mx-auto flex text-[#fff] px-10 py-[10px] mt-8 text-[14px] font-medium rounded-3xl"
              >
                Complete
              </button>
            </div>
          </div>
        ) : (
          <div className="max-h-[80vh]">
            {/* <button
              className="absolute flex items-center font-[500] gap-1 lg:left-8 left-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
              onClick={goBack}
            >
              <Icon
                icon="eva:arrow-ios-back-outline"
                className="text-[#2D2D2D] text-lg"
              />{' '}
              Back
            </button> */}
            <button
              className="absolute flex items-center font-[500] gap-1 lg:right-8 right-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
              onClick={onClose}
            >
              Close
              <Icon
                icon="ic:outline-close"
                className="text-[#2D2D2D] text-lg"
              />
            </button>
            <h2 className="text-[16px] font-[500] text-center mb-8 text-[#505050]">
              Donate Items{' '}
            </h2>

            <p className="font-[500] mb-4">Personal information</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-3">
                      <CustomInput
                        id="Firstname"
                        name="Firstname"
                        type="text"
                        label="FIRST NAME"
                        placeholder="Enter first name"
                        isRequired={true}
                      />
                      <CustomInput
                        id="Lastname"
                        name="Lastname"
                        type="text"
                        label="LAST NAME"
                        placeholder="Enter last name"
                        isRequired={true}
                      />
                    </div>
                    <CustomInput
                      id="Phone"
                      name="Phone"
                      type="number"
                      label="PHONE NUMBER"
                      isRequired={true}
                    />
                    <CustomInput
                      id="Email"
                      name="Email"
                      type="email"
                      label="EMAIL"
                      isRequired={true}
                    />
                    <div>
                      <label className="text-[#505050] text-[10px] tracking-widest block mb-1 uppercase">
                        <span>Items to Donate</span>
                      </label>
                      <div className="gap-4 flex">
                        <div className="flex items-center">
                          <Field
                            type="checkbox"
                            id="clothes"
                            name="clothes"
                            className="form-checkbox h-3 w-3 accent-[#A825B3]"
                            onChange={(e) =>
                              handleCheckboxChange(
                                'clothes',
                                setFieldValue,
                                e,
                                values
                              )
                            }
                          />
                          <label htmlFor="clothes" className="ml-1 text-[12px]">
                            Clothes
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            type="checkbox"
                            id="shoes"
                            name="shoes"
                            className="form-checkbox h-3 w-3 accent-[#A825B3]"
                            onChange={(e) =>
                              handleCheckboxChange(
                                'shoes',
                                setFieldValue,
                                e,
                                values
                              )
                            }
                          />
                          <label htmlFor="shoes" className="ml-1 text-[12px]">
                            Shoes
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            type="checkbox"
                            id="books"
                            name="books"
                            className="form-checkbox h-3 w-3 accent-[#A825B3]"
                            onChange={(e) =>
                              handleCheckboxChange(
                                'books',
                                setFieldValue,
                                e,
                                values
                              )
                            }
                          />
                          <label htmlFor="books" className="ml-1 text-[12px]">
                            Books
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            type="checkbox"
                            id="others"
                            name="others"
                            className="form-checkbox h-3 w-3 accent-[#A825B3]"
                            onChange={(e) =>
                              handleCheckboxChange(
                                'others',
                                setFieldValue,
                                e,
                                values
                              )
                            }
                          />
                          <label htmlFor="others" className="ml-1 text-[12px]">
                            Others
                          </label>
                        </div>
                      </div>
                    </div>

                    {showOtherInput && (
                      <CustomInput
                        id="otherItem"
                        name="otherItem"
                        type="text"
                        label="OTHER ITEMS"
                        placeholder="Enter other items"
                      />
                    )}
                    <div className="relative">
                      <label className=" text-[#505050] text-[10px] tracking-widest block mb-1">
                        <span>
                          DROP-OFF LOCATION{' '}
                          <span className="text-[#F54135]">*</span>
                        </span>
                      </label>

                      <button
                        type="button"
                        aria-expanded={toggle}
                        onClick={() => setToggle(!toggle)}
                        className={`flex items-center justify-between p-3 w-full text-xs rounded-md  border-[1px]`}
                      >
                        <p>{values?.Location || 'Choose location'}</p>

                        <div
                          className={`transform transition-all ${
                            toggle ? 'rotate-0' : 'rotate-180'
                          }`}
                        >
                          <Icon
                            icon={'ic:outline-keyboard-arrow-down'}
                            color="#BFBFBF"
                            height={20}
                          />
                        </div>
                      </button>

                      {toggle && (
                        <ul
                          className={`absolute z-20 mt-1 flex max-h-[132px] w-full flex-col overflow-auto rounded-md border-2   `}
                        >
                          {[
                            'lsc the bridge - ikeja',
                            'lsc home indeed - omole',
                            'old international office - rccg city',
                          ].map((item) => (
                            <li
                              onClick={() => {
                                setToggle(false);
                                setFieldValue('Location', item.toUpperCase());
                              }}
                              className={`p-2 text-left uppercase text-sm bg-[#fff] hover:bg-[#BFBFBF] hover:text-white cursor-pointer`}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="flex gap-2 items-center">
                      <Field
                        type="checkbox"
                        id="AgreeCondition"
                        name="AgreeCondition"
                        className="form-checkbox h-5 w-5 text-[#A825B3]"
                      />
                      <label htmlFor="AgreeCondition" className="text-xs">
                        I affirm that the items I am donating are in good
                        condition.
                      </label>
                    </div>
                  </div>
                  {isError && (
                    <p className="text-red text-xs">
                      Oops, Something went wrong!
                    </p>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[#A825B3] disabled:opacity-60 lg:w-fit w-full shadow  text-[#fff] px-10 py-[10px]  mt-8 lg:mb-0 mb-10 text-[14px] font-medium rounded-3xl"
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonateCloth;
