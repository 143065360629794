import React, { useState } from 'react';
import DonateCloth from './DonateCloth';
// import DonateOptions from './DonateOptions';
import DonateMoney from './DonateMoney';
import AccountDetails from './AccountDetails';

const DonateModal = ({ onClose }) => {
  const [step, setStep] = useState(2);

  return (
    <div>
      {/* {step === 1 && (
        <DonateOptions
          onClose={() => {
            onClose();
            setStep(1);
          }}
          setStep={setStep}
        />
      )} */}

      {step === 2 && (
        <DonateCloth
          onClose={() => {
            onClose();
            setStep(1);
          }}
          goBack={() => setStep(1)}
        />
      )}

      {step === 3 && (
        <DonateMoney
          onClose={() => {
            onClose();
            setStep(1);
          }}
          goBack={() => setStep(1)}
          nextPage={() => setStep(4)}
        />
      )}

      {step === 4 && (
        <AccountDetails
          onClose={() => {
            onClose();
            setStep(1);
          }}
          goBack={() => setStep(3)}
        />
      )}
    </div>
  );
};

export default DonateModal;
