import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Event from './pages/event';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Event />} />
          <Route path="/register" element={<Event />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
