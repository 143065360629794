import { Icon } from '@iconify-icon/react';
import React from 'react';
import accountImage from '../assets/account.png';

const AccountDetails = ({ onClose, goBack }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`relative bg-white lg:h-fit h-full  overflow-scroll  lg:rounded-3xl shadow-lg py-10 lg:px-14 px-5 lg:w-[40%] w-[100%] `}
      >
        <div>
          <button
            className="absolute flex items-center font-[500] gap-1 lg:left-8 left-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
            onClick={goBack}
          >
            <Icon
              icon="eva:arrow-ios-back-outline"
              className="text-[#2D2D2D] text-lg"
            />{' '}
            Back
          </button>
          <button
            className="absolute flex items-center font-[500] gap-1 lg:right-8 right-5 border border-[#5050504D] lg:text-sm text-[13px]  text-[#A825B3] px-2 py-1 rounded-2xl"
            onClick={onClose}
          >
            Close
            <Icon icon="ic:outline-close" className="text-[#2D2D2D] text-lg" />
          </button>
          <h2 className="text-[16px] font-[500] text-center mb-10 text-[#505050]">
            Donate Money{' '}
          </h2>
          <img
            src={accountImage}
            alt="accountImage"
            // className=" h-[250px]  hidden lg:block"
          />
          <div className="mt-4">
            {' '}
            <span className="text-[#F6411B]"> Reference:</span>{' '}
            <span className="text-[#2D2D2D]">Jesus in the Park Donation</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
