import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CustomInput = ({
  id,
  name,
  placeholder,
  type,
  label,
  className,
  labelClassName,
  formikProps,
  showIcon,
  isRequired,
  onKeyPress,
  disabled,
  defaultValue,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  console.info(field, meta, helpers);
  return (
    <Reveal keyframes={customAnimation} triggerOnce>
      <div>
        <label
          className=" text-[#505050] text-[10px] tracking-widest block mb-1"
          htmlFor={name}
        >
          <span className={labelClassName}>
            {label} {isRequired && <span className="text-[#F54135]">*</span>}
          </span>
        </label>
        <div className="relative">
          <Field
            disabled={disabled}
            type={type}
            id={id}
            name={name}
            className={` ${className} w-full p-4 focus:outline-[#CFD5DC] focus:outline text-sm  text-[#130E23] rounded-[12px] border-[0.5px] border-[#CFD5DC] placeholder:text-[#505050B2] placeholder:text-[13px] `}
            placeholder={placeholder}
            {...(defaultValue && { defaultValue: defaultValue })}
            {...props}
          />
        </div>

        <ErrorMessage
          name={name}
          component="div"
          className="text-[rgb(239_68_68)] text-[11px] mt-1"
        />
      </div>
    </Reveal>
  );
};

export default CustomInput;
